var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"fade-transition","max-width":"600px","content-class":"elevation-0","hide-overlay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(hover)?_c('v-btn',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"blue1 white--text",attrs:{"depressed":"","block":"","height":"40","rounded":""},on:{"click":_vm.next}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-donate fa-2x "})]):_c('v-btn',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"blue0 white--text",attrs:{"depressed":"","block":"","height":"40","rounded":""},on:{"click":_vm.next}},'v-btn',attrs,false),on),[_vm._v(" 과비 납부하기 ")])],1)]}}],null,true)})]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-corner cardborder",attrs:{"elevation":"0"}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],attrs:{"color":"blue0","icon":"","plain":""},on:{"click":_vm.before}},[_c('i',{staticClass:"fas fa-arrow-left fa-2x"})]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"toolbar-font blue1--text"},[_vm._v(" 납부 정보 ")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],attrs:{"color":"blue0","icon":"","plain":""},on:{"click":function($event){_vm.dialog=false}}},[_c('i',{staticClass:"fas fa-times fa-2x"})])],1),_c('v-card-text',{staticClass:"text-center"},[_c('h',{staticClass:"maintext"},[_vm._v(" 우리 1002-562-497630 허찬"),_c('div'),_vm._v(" 입금자명: ‘이름+학번뒷5자리’ (ex. ‘나연90111’)"),_c('div'),_vm._v(" 금액: 3만원 ")]),_c('input',{attrs:{"type":"hidden","id":"accountInfo"}}),_c('v-row',{staticClass:"mt-2 px-2 pb-2",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-1 px-1",attrs:{"cols":"12","sm":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(hover)?_c('v-btn',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"blue1 white--text",attrs:{"depressed":"","block":"","height":"40","rounded":""},on:{"click":function($event){return _vm.copyAccount()}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.btext2)+" ")]):_c('v-btn',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"blue0 white--text",attrs:{"depressed":"","block":"","height":"40","rounded":""},on:{"click":function($event){return _vm.copyAccount()}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.btext)+" ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }